<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    style="enable-background: new 0 0 24 24"
    xml:space="preserve"
  >
    <title>list-stars</title>
    <g>
      <path d="M8.25,4.498c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75h15c0.414,0,0.75,0.336,0.75,0.75s-0.336,0.75-0.75,0.75H8.25z" />
      <path d="M8.25,13.498c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75h15c0.414,0,0.75,0.336,0.75,0.75s-0.336,0.75-0.75,0.75H8.25z" />
      <path d="M8.25,22.498c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75h15c0.414,0,0.75,0.336,0.75,0.75s-0.336,0.75-0.75,0.75H8.25z" />
      <path
        d="M5.25,5.998c-0.2,0-0.389-0.078-0.53-0.22L3,4.059l-1.72,1.72c-0.142,0.142-0.33,0.22-0.53,0.22S0.361,5.92,0.22,5.778C0.078,5.637,0,5.448,0,5.248s0.078-0.389,0.22-0.53l1.72-1.72l-1.72-1.72C0.078,1.137,0,0.948,0,0.748s0.078-0.389,0.22-0.53c0.141-0.142,0.33-0.22,0.53-0.22s0.389,0.078,0.53,0.22L3,1.938l1.72-1.72c0.142-0.142,0.33-0.22,0.53-0.22s0.389,0.078,0.53,0.22C5.922,0.359,6,0.548,6,0.748s-0.078,0.389-0.22,0.53l-1.72,1.72l1.72,1.72C5.922,4.859,6,5.048,6,5.248s-0.078,0.389-0.22,0.53C5.639,5.92,5.45,5.998,5.25,5.998z"
      />
      <path
        d="M5.25,14.998c-0.2,0-0.389-0.078-0.53-0.22L3,13.059l-1.72,1.72c-0.142,0.142-0.33,0.22-0.53,0.22s-0.389-0.078-0.53-0.22c-0.292-0.292-0.292-0.768,0-1.061l1.72-1.72l-1.72-1.72C0.078,10.137,0,9.948,0,9.748s0.078-0.389,0.22-0.53c0.141-0.142,0.33-0.22,0.53-0.22s0.389,0.078,0.53,0.22L3,10.938l1.72-1.72c0.142-0.142,0.33-0.22,0.53-0.22s0.389,0.078,0.53,0.22C5.922,9.359,6,9.548,6,9.748s-0.078,0.389-0.22,0.53l-1.72,1.72l1.72,1.72c0.292,0.292,0.292,0.768,0,1.061C5.639,14.92,5.45,14.998,5.25,14.998z"
      />
      <path
        d="M5.25,23.998c-0.2,0-0.389-0.078-0.53-0.22L3,22.059l-1.72,1.72c-0.142,0.142-0.33,0.22-0.53,0.22s-0.389-0.078-0.53-0.22c-0.292-0.292-0.292-0.768,0-1.061l1.72-1.72l-1.72-1.72c-0.292-0.292-0.292-0.768,0-1.061c0.142-0.142,0.33-0.22,0.53-0.22s0.389,0.078,0.53,0.22L3,19.938l1.72-1.72c0.142-0.142,0.33-0.22,0.53-0.22s0.389,0.078,0.53,0.22c0.292,0.292,0.292,0.768,0,1.061l-1.72,1.72l1.72,1.72c0.292,0.292,0.292,0.768,0,1.061C5.639,23.92,5.45,23.998,5.25,23.998z"
      />
    </g>
  </svg>
</template>
